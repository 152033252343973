import SlimSelect from "slim-select";

export default function initSelects() {

    const selects = Array.from(document.querySelectorAll('.js-select'));

    selects.forEach((select) => {
        new SlimSelect({
            select: select,
            showSearch: false,
            onChange: () => {
                select.classList.add('is-touched')
            },
            afterOpen: () => {
                select.closest('.input-wrapper.--float-label')?.classList.add('is-touched', 'is-open');
            },
            beforeClose: () => {
                if (!select.classList.contains('is-touched')) {
                    select.closest('.input-wrapper.--float-label')?.classList.remove('is-touched');
                }
            },
            afterClose: () => {
                select.closest('.input-wrapper.--float-label')?.classList.remove('is-open');
            },
        });
    })
}

import './Marquee.scss';
import { LitElement, css, html, unsafeCSS } from 'lit';
import MarqueeConstructor from './marquee';
import _styles from './MarqueeText.ce.scss';

export interface MarqueeText {
    speedKoef: number;
    speed: number;
    _marquee: MarqueeConstructor | undefined;
}

export class MarqueeText extends LitElement {
    constructor() {
        super();
        this.speedKoef = this.dataset.speedKoef ? parseFloat(this.dataset.speedKoef) : 1;
        this.speed = 0.8 * this.speedKoef;
    }

    static get properties() {
        return {
            speed: {
                type: Number,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        setTimeout(() => {
            const container = this.renderRoot.querySelector<HTMLElement>('.container');
            if (container) {
                this._marquee = new MarqueeConstructor(container, {
                    speed: this.speed,
                    pausable: this.hasAttribute('pausable'),
                    reverse: this.hasAttribute('reversed'),
                });
            }
        }, 0);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._marquee?.destroy();
    }

    render() {
        return html`
            <div class="container">
                <div class="text" .innerHTML="${this.innerHTML}"></div>
            </div>
        `;
    }
}

window.customElements.define('app-marquee', MarqueeText);

declare global {
    interface HTMLElementTagNameMap {
        'app-marquee': MarqueeText;
    }
}

const onClick = (event: Event) => {
    event.stopPropagation();
    const eventTarget = event.target as HTMLElement;
    const parent = eventTarget.parentElement;

    if (parent && parent.classList.contains('menu-item-has-children')) {
        parent.classList.toggle('is-active');
    }
}

export default function menuCollapsesInit() {
    const collapseTriggers = Array.from(document.querySelectorAll('.menu-item-has-children'));

    collapseTriggers.forEach(trigger => {
        trigger.addEventListener('click', onClick);
    })
}
import Swiper,  { Pagination, Navigation } from 'swiper';
import 'swiper/css';

export default function sliderPaginationInit() {
    const sliderContainers = Array.from(document.querySelectorAll<HTMLElement>('.js-slider-pagination-container'));

    sliderContainers.forEach((container) => {
        const sliderElement = container.querySelector<HTMLElement>('.js-slider-element')
        const paginationElement = container.querySelector<HTMLElement>('.js-slider-pagination');
        const navigationElement = container.querySelector<HTMLElement>('.js-slider-navigation');

        let navigationNextEl;
        let navigationPrevEl;

        if (navigationElement) {
            navigationNextEl = navigationElement.querySelector<HTMLElement>('.js-slider-arrow-next');
            navigationPrevEl = navigationElement.querySelector<HTMLElement>('.js-slider-arrow-prev');
        }

        if (sliderElement) {
            const slides = sliderElement.querySelectorAll('.swiper-slide');
            const isChangeImages = sliderElement.classList.contains('js-slider-change-image');
            const changeImageTarget = document.querySelector('.js-slider-change-image-target');

            if (slides.length < 2) {
                navigationElement?.classList.add('visually-hidden')
                return
            }

            const swiper = new Swiper(sliderElement, {
                modules: [Pagination, Navigation],
                loop: true,
                slidesPerView: 1,
                grabCursor: true,
                on: {
                    slideChange: (instance) => {
                        if (isChangeImages) {
                            const currentSlideImageSrc = instance.slides[instance.activeIndex].dataset.image;

                            if (changeImageTarget) {
                                changeImageTarget.setAttribute('src', currentSlideImageSrc);
                            }
                        }
                    }
                },
                pagination: paginationElement ? {
                    el: paginationElement,
                    enabled: true,
                    clickable: true,
                    type: 'bullets',
                    renderBullet: (index, className) => {
                        return `<span class="number-pagination__item ${className}"><span>${index + 1}</span></span>`
                    }
                } : false,

                navigation: navigationNextEl && navigationPrevEl ? {
                    nextEl: navigationNextEl,
                    prevEl: navigationPrevEl,
                } : false,
            });
        }
    })
}
import axios from 'axios';

export default function axiosFormsInit() {
    const forms = Array.from(document.querySelectorAll<HTMLFormElement>('.js-form'));

    forms.forEach(form => {
        form.addEventListener('submit', (event) => {
            event.preventDefault();
            const formData = new FormData(form);
            const action = form.getAttribute('action');

            if (formData) {
                Object.entries(formData).forEach((entry) => {
                    formData!.append(...entry);
                });
            }

            if (action) {
                axios.post(action, {
                    formData
                }).then((response) => {
                    console.log(response);
                })
            }
        });
    })
}

const onOpen = (e: Event) => {
    const event = e as CustomEvent;
    const popupTitle = event.detail.triggerElement.dataset.ctaTitle;
    const popupAction = event.detail.triggerElement.dataset.ctaAction;
    // action, и прочие скрытые инпуты
    const titleElement = event.target.querySelector('.js-form-title');
    const actionElement = event.target.querySelector<HTMLInputElement>('.js-form-action');
    titleElement.innerHTML = popupTitle ? popupTitle : 'Заголовок формы';
    actionElement.value = popupAction ? popupAction : '';
}
export default function ctaFormInit() {
    const ctaPopup = document.querySelector('[data-lit-popup="cta"]');
    ctaPopup?.addEventListener('open', onOpen);
}
import SlimSelect from "slim-select";

type SelectItem = {
    text: string;
    value: string;
    selected?: boolean;
}

export default function langChangeInit() {
    const selectsWrappers = Array.from(document.querySelectorAll('.js-control-lang'));

    selectsWrappers.forEach(wrapper => {
        const links = Array.from(wrapper.querySelectorAll('a'));
        const data:SelectItem[]  = [];

        links.forEach(link => {
            const selected = link.closest('li').classList.contains('current-lang');
            const text = link.innerHTML;
            const value = link.getAttribute('href') || '';
            data.push({text, value, selected});
        });

        const select = document.createElement('select');
        select.classList.add('select-lang');
        select.classList.add('select');
        select.classList.add('select-form');


        wrapper.innerHTML = '';
        wrapper.appendChild(select);

        new SlimSelect({
            select: select,
            data,
            showSearch: false,
            onChange: (event) => {
                window.location = event.value;
            }
        });
    });
}
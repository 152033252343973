export default function revealInit() {
    const elements = Array.from(document.querySelectorAll<HTMLElement>('[data-reveal]'));
    elements.forEach((element) => {
        const rootMargin = element.dataset.rootMargin || '0% -10% 0% -10%';
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        observer.unobserve(entry.target);
                        entry.target.classList.add('is-reveal');
                    }
                });
            },
            {
                rootMargin,
                threshold: [0, 1],
            },
        );
        observer.observe(element);
    });
}


export default function initFileInputs() {
    const fileInputs = Array.from(document.querySelectorAll<HTMLInputElement>('input[type="file"]'));

    fileInputs.forEach(input => {
        input.addEventListener('change', (e: Event) => {
            console.log(e.target.files[0].name);
            const fakeInput = input.closest('div')?.querySelector('.js-file-input');
            if (fakeInput && e.target.files[0].name) {
                fakeInput.innerHTML = e.target.files[0].name;
            }
        })
    })
}
export default function storiesCollapseInit() {
    const collapseGroup = Array.from(document.querySelectorAll('.js-collapse-group'));

    collapseGroup.forEach((group) => {
        const collapses = Array.from(group.querySelectorAll('.js-collapse-item'));
        const triggers = Array.from(group.querySelectorAll('.js-collapse-trigger'));

        triggers.forEach((trigger) => {
            trigger.addEventListener('click', (event: Event) => {
                const currentCollapse = event.target.closest('.js-collapse-item');

                collapses.forEach((collapse) => {
                    if (collapse !== currentCollapse) {
                        collapse.classList.remove('active');
                    } else {
                        collapse.classList.add('active');
                    }
                })
            })
        })
    });
}
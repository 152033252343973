export default function headerThemeObserverInit() {
    const header = document.querySelector('.js-header');
    const commonRootMargin = '-10% 0% -90% 0%';
    const callback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
            if (header) {
                if (entry.isIntersecting) {
                    const target = entry.target as HTMLElement;
                    target.dataset.headerTheme == 'dark'
                        ? header?.classList.add('dark-theme')
                        : header?.classList.remove('dark-theme');
                }
            }
        });
    };

    const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

    const elements = Array.from(document.querySelectorAll<HTMLElement>('[data-header-theme]'));

    elements.forEach((el) => {
        const rootMargin = el.dataset.rootMargin || commonRootMargin;
        const observer =
            rootMargin === commonRootMargin
                ? commonObserver
                : new IntersectionObserver(callback, { rootMargin, threshold: [0, 1] });

        observer.observe(el);
    });
}


let headerHeight = 0;
let prevScrollpos = window.pageYOffset;

function onScroll() {
    const currentScrollPos = window.pageYOffset;

    if (prevScrollpos <= currentScrollPos) {
        if (currentScrollPos > headerHeight) {
            document.documentElement.dataset.direction = '1';
        }
    } else {
        if (currentScrollPos > headerHeight) {
            document.documentElement.dataset.direction = '-1';
        }
    }

    prevScrollpos = currentScrollPos;
}

export default function hideHeaderOnScrollInit() {
    const header = document.querySelector<HTMLElement>('.js-header');
    if (header) {
        headerHeight = header.offsetHeight;
    }

    document.addEventListener('scroll', onScroll);
}
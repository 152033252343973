import gsap from 'gsap';

export default function strokeAnimations() {
	const stroke = document.querySelector('.a-stroke-container');

    if (stroke) {
        const pathes = Array.from(stroke.querySelectorAll('path[stroke-width="2"]'));

		const tl = gsap.timeline({defaults:{repeat: -1, duration: 3, yoyo: true }, stagger: {each: 0.05, from: 'start'}});

		tl.fromTo(pathes, {opacity: 0}, {opacity: 1})
    }
}
import loadScript from '../../modules/load-script';

let youtubeLoaded = false;
let player: any;

function YouTubeGetID(url: string) {
    let ID = '';
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
    } else {
        ID = url;
    }
    return ID;
}

export default function youtubePlayerInit() {
    const videoTrigger = document.querySelector<HTMLElement>('.js-video-player-trigger')!;

    if (!youtubeLoaded) {
        loadScript('https://www.youtube.com/iframe_api').then(() => {
            youtubeLoaded = true
        })
    }

    const onYoutubeReady = () => {
        const iframe = document.querySelector<HTMLIFrameElement>('.js-video-player');

        if (iframe) {
            const videoLink = iframe.dataset.videoId;
            const videoId = YouTubeGetID(videoLink);

            function playVideoHandler () {
                player.playVideo();
            }


            function onPlayerReady () {
                videoTrigger.addEventListener('click', playVideoHandler);
            }

            function onPlayerStateChange(event: any) {
                console.log(event);
            }

            player = new (window as any).YT.Player(iframe, {
                height: '100%',
                width: '100%',
                videoId,
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
            });
        }


    }

    if (youtubeLoaded) {
        onYoutubeReady();
    } else {
        (window as any).onYouTubeIframeAPIReady = onYoutubeReady;
    }
}


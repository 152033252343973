import gsap from 'gsap';

export default function lineAnimations() {
	const lineContainers = Array.from(document.querySelectorAll('.a-lines-container'));

	lineContainers.forEach((container) => {
		const lines = Array.from(container.querySelectorAll('.a-line'));

		const tl = gsap.timeline();

		tl.from(lines, {opacity: 0, repeat: -1, stagger: {from: 'random', each: 0.05}, duration: Math.floor(Math.random() * (2 - 1 + 1) + 1)
		, yoyo: true })
	})
}
import datepicker from 'js-datepicker';

export default function initDatepickers() {
    const datepickers = document.querySelectorAll('.js-input-date');

    datepickers.forEach((element) => {
        datepicker(element, {
            startDay: 1,
            minDate: new Date(),
            noWeekends: true,
        });
    });
}

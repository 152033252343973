import { fetchIMask } from '../dynamic-modules';

export async function initPhoneMask(input: HTMLInputElement, dialCode = '7') {
    const IMask = await fetchIMask();
    const imask = IMask(input, { mask: `+{${dialCode}} 000 000 00 00` });
}


export default function initPhoneMasks(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>('.wpcf7-form input[type="tel"]:not(.no-mask)'));

    if (elements.length > 0) {
        elements.forEach((el) => {
            initPhoneMask(el);
        });
    }
}
